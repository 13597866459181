/* eslint-disable react/jsx-no-target-blank */
import {
  ContentText,
  CopyrightText,
  Footer,
  Icon,
  IconArea,
  SalesAnnounce,
  TermsArea,
  TextButton,
  Title,
  VerticalBar,
} from "@/components/FooterArea/index.style";
import Link from "next/link";

const FooterArea = () => {
  const staticIsMobile = true;
  return (
    <Footer isMobile={staticIsMobile}>
      <Title>(주)메라키플레이스</Title>

      <SalesAnnounce>
        비즈니스/광고 제휴 문의: sales@merakiplace.kr
      </SalesAnnounce>

      <ContentText>
        대표: 선재원, 손웅래 | 사업자등록번호: 554-86-02396
        <br />
        통신판매업신고: 2024-서울영등포-2562
        <br />
        서울 강남구 테헤란로 151 역삼하이츠빌딩 7층 710호
        <br />
        이메일: help@merakiplace.kr | 전화번호: 1544-6172
        <br />
        고객센터 운영시간: 평일 오전 10시 ~ 오후 7시
        <br />
        (점심시간: 오후 12시 ~ 오후 1시)
      </ContentText>
      {staticIsMobile ? (
        <>
          <IconArea isMobile={staticIsMobile}>
            <a
              href={"https://post.naver.com/my.naver?memberNo=57509234"}
              target="_blank"
              rel="noopener"
              title="naver-post"
            >
              <Icon
                src="/icon/ico_naver_post.png"
                alt="naver_post"
                loading="lazy"
              />
            </a>
            <a
              href={"https://www.instagram.com/my_doctor_official"}
              target="_blank"
              rel="noopener"
              title="instagram"
            >
              <Icon
                src="/icon/ico_instagram.png"
                alt="instagram"
                loading="lazy"
              />
            </a>
            <a
              href={"https://blog.naver.com/merakiplace_official"}
              target="_blank"
              rel="noopener"
              title="naver-blog"
            >
              <Icon
                src="/icon/ico_naver_blog.png"
                alt="naver_blog"
                loading="lazy"
              />
            </a>
            <a
              href={"https://blog.naver.com/merakiplace_official"}
              target="_blank"
              rel="noopener"
              title="youtube"
            >
              <Icon src="/icon/ico_youtube.png" alt="youtube" loading="lazy" />
            </a>
          </IconArea>
          <TermsArea isMobile={staticIsMobile}>
            <Link href="/sitemap">
              <a style={{ textDecoration: "none" }} target="_blank">
                <TextButton isMobile={staticIsMobile}>사이트맵</TextButton>
              </a>
            </Link>
            <VerticalBar isMobile={staticIsMobile}>|</VerticalBar>
            <Link href="/terms">
              <a style={{ textDecoration: "none" }} target="_blank">
                <TextButton isMobile={staticIsMobile}>이용약관</TextButton>
              </a>
            </Link>
            <VerticalBar isMobile={staticIsMobile}>|</VerticalBar>
            <Link href="/privacyPolicy">
              <a style={{ textDecoration: "none" }} target="_blank">
                <TextButton isMobile={staticIsMobile}>
                  <b>개인정보처리방침</b>
                </TextButton>
              </a>
            </Link>
            <VerticalBar isMobile={staticIsMobile}>|</VerticalBar>
          </TermsArea>
          <TermsArea isMobile={staticIsMobile}>
            <Link href="/locationServiceTerms">
              <a style={{ textDecoration: "none" }} target="_blank">
                <TextButton isMobile={staticIsMobile}>
                  위치기반서비스이용약관
                </TextButton>
              </a>
            </Link>
          </TermsArea>
          <TermsArea isMobile={staticIsMobile}></TermsArea>
        </>
      ) : (
        <>
          <TermsArea isMobile={staticIsMobile}>
            <Link href="/terms">
              <a style={{ textDecoration: "none" }} target="_blank">
                <TextButton isMobile={staticIsMobile}>이용약관</TextButton>
              </a>
            </Link>
            <VerticalBar isMobile={staticIsMobile}>|</VerticalBar>
            <Link href="/privacyPolicy">
              <a style={{ textDecoration: "none" }} target="_blank">
                <TextButton isMobile={staticIsMobile}>
                  <b>개인정보처리방침</b>
                </TextButton>
              </a>
            </Link>
            <VerticalBar isMobile={staticIsMobile}>|</VerticalBar>
            <Link href="/locationServiceTerms">
              <a style={{ textDecoration: "none" }} target="_blank">
                <TextButton isMobile={staticIsMobile}>
                  위치기반서비스이용약관
                </TextButton>
              </a>
            </Link>
          </TermsArea>
          <IconArea isMobile={staticIsMobile}>
            <Icon
              src="icon/ico_naver_post.png"
              alt="naver_post"
              loading="lazy"
              onClick={() =>
                window.open(
                  "https://post.naver.com/my.naver?memberNo=57509234",
                  "_blank"
                )
              }
            />
            <Icon
              src="icon/ico_instagram.png"
              alt="instagram"
              loading="lazy"
              onClick={() =>
                window.open(
                  "https://www.instagram.com/my_doctor_official",
                  "_blank"
                )
              }
            />
            <Icon
              src="icon/ico_naver_blog.png"
              alt="naver_blog"
              loading="lazy"
              onClick={() =>
                window.open(
                  "https://blog.naver.com/merakiplace_official",
                  "_blank"
                )
              }
            />
            <Icon
              src="icon/ico_youtube.png"
              alt="youtube"
              loading="lazy"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UC2IlIcvp7ffOfl1ue-vMfGw",
                  "_blank"
                )
              }
            />
          </IconArea>
        </>
      )}
      <CopyrightText>
        <br />
        Copyright 2024 Merakiplace Inc. All rights reserved.
      </CopyrightText>
    </Footer>
  );
};

export default FooterArea;
